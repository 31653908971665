<template>
  <header class="pro-header">
    <div>
      <div class="inner-container full-width">
        <div class="menu-top">
          <div class="icon-menu u-bg-night-blue u-m-l-md show-sm pointer" @click="toggleMenu('mobile')" />
          <NuxtLinkLocale class="menu-top-index" :to="'index'" @click="closeAndRedirect">
            <img
              src="~/assets/logos/logo-stockpro-color.svg"
              :alt="$t('header.logoAlt')"
              :title="$t('header.logoAlt')"
              class="logo"
            />
          </NuxtLinkLocale>
          <ul v-show="displayFullMenu" class="menu-top-pages hide-sm">
            <li>
              <NuxtLinkLocale
                :to="'index'"
                :class="{ active: $route.path === 'index' }"
                data-test-id="home-pro-header-home"
                @click="closeAndRedirect"
              >
                {{ $t('header.home') }}
              </NuxtLinkLocale>
            </li>
            <li>
              <a
                :class="{ active: currentMenu === 'services' || $route.path.includes('services') }"
                data-test-id="home-pro-header-services"
                @click="currentMenu === 'services' ? toggleMenu(null, 0) : toggleMenu('services')"
              >
                {{ $t('header.services') }}
              </a>
            </li>
            <li>
              <a
                :class="{ active: currentMenu === 'offers' || $route.path.includes('offers') }"
                data-test-id="home-pro-header-offers"
                @click="currentMenu === 'offers' ? toggleMenu(null, 0) : toggleMenu('offers')"
              >
                {{ $t('header.offers') }}
              </a>
            </li>
            <li>
              <NuxtLinkLocale
                :to="'tarifs'"
                :class="{ active: $route.path === 'tarifs' }"
                data-test-id="home-pro-header-plans"
                @click="toggleMenu(null)"
              >
                {{ $t('header.plans') }}
              </NuxtLinkLocale>
            </li>
          </ul>
          <div class="menu-top-pages show-sm" />
          <ClientOnly>
            <LangSwitcher v-if="availableLanguages?.length > 1" class="u-m-r-md u-bg-white" />
            <MainButton
              id="myToolsButton"
              tag="a"
              data-test-id="home-pro-header-my-tools"
              :label="myToolLabel"
              :class-variant="[
                'btn-radius-lg',
                isMd ? 'icon-only' : '',
                !user.isAuthenticated || !user.isPro ? 'btn-clear-blue' : '',
              ]"
              @click="myToolsLink"
            >
              <template #iconRight>
                <div v-if="user.isAuthenticated" class="icon-tools u-bg-white" :class="{ 'u-m-l-xs': !isMd }" />
                <div v-if="!user.isAuthenticated && isMd" class="icon-home u-bg-white" />
              </template>
            </MainButton>
            <div
              class="nav-user-wrapper"
              :class="{ 'submenu-toggle': user.isAuthenticated, 'is-active': showMenu }"
              @mouseover="user.isAuthenticated ? (showMenu = true) : null"
              @mouseleave="user.isAuthenticated ? (showMenu = false) : null"
            >
              <span v-if="user.isAuthenticated" class="user-info">
                <span class="user-name">{{ user.fullName }}</span>
                <span v-if="user.isPro" class="user-company u-c-primary">
                  {{ user.companyName }}
                </span>
              </span>
              <NuxtLinkLocale v-else :to="'login'" class="user-login">
                {{ $t('header.login') }}
              </NuxtLinkLocale>
              <div class="nav-user">
                <MainButton
                  :link="user.isAuthenticated ? '' : localePath('login')"
                  :class-variant="['icon-only', user.isAuthenticated ? 'btn-blue' : 'btn-bg-blue']"
                >
                  <template #iconRight>
                    <div class="icon-user" :class="user.isAuthenticated ? 'u-bg-white' : 'u-bg-night-blue'" />
                  </template>
                </MainButton>
                <nav v-if="user.isAuthenticated" class="submenu" @click="showMenu = false">
                  <ul>
                    <li>
                      <NuxtLinkLocale :to="'index'" :title="$t('header.logOut')" @click="logout">
                        <img
                          src="@/assets/icons/ico-connect-red.svg"
                          width="19"
                          :alt="$t('header.logOut')"
                          :title="$t('header.logOut')"
                        />
                        <span>{{ $t('header.logOut') }}</span>
                      </NuxtLinkLocale>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </ClientOnly>
        </div>
      </div>
      <div v-if="currentMenu === 'mobile'" class="menu-top-mobile">
        <div class="menu-top-logo-mobile">
          <NuxtLinkLocale :to="'index'" @click="closeAndRedirect()">
            <img
              src="~/assets/logos/logo-stockpro-color.svg"
              :alt="$t('header.logoAlt')"
              :title="$t('header.logoAlt')"
              class="logo"
            />
          </NuxtLinkLocale>
        </div>
        <ul class="menu-top-pages">
          <li>
            <NuxtLinkLocale :to="'index'" :class="{ active: $route.path === 'index' }" @click="closeAndRedirect()">
              {{ $t('header.home') }}
            </NuxtLinkLocale>
          </li>
          <li>
            <a
              :class="{ active: currentMenu === 'services' || $route.path.includes('services') }"
              @click="toggleMenu('services')"
            >
              {{ $t('header.services') }}
            </a>
          </li>
          <li>
            <a
              :class="{ active: currentMenu === 'offers' || $route.path.includes('offers') }"
              @click="toggleMenu('offers')"
            >
              {{ $t('header.offers') }}
            </a>
          </li>
          <li>
            <NuxtLinkLocale :to="'tarifs'" :class="{ active: $route.path === 'tarifs' }" @click="toggleMenu(null)">
              {{ $t('header.plans') }}
            </NuxtLinkLocale>
          </li>
        </ul>
        <div class="backdrop" @click="toggleMenu('mobile')" />
      </div>
      <div v-else-if="currentMenu === 'offers'" v-click-outside="() => toggleMenu(null, 0)" class="menu-detail">
        <div class="menu-offers">
          <div class="menu-top-logo-mobile show-sm">
            <NuxtLinkLocale :to="'index'">
              <img
                src="~/assets/logos/logo-stockpro-color.svg"
                :alt="$t('header.logoAlt')"
                :title="$t('header.logoAlt')"
                class="logo"
              />
            </NuxtLinkLocale>
          </div>
          <h3 class="menu-offers-title">
            <div class="icon-arrow-left u-bg-black show-sm pointer" @click="toggleMenu('mobile')" />
            {{ $t('proArea.offers.predefined') }}
          </h3>
          <div class="menu-offers-list">
            <div v-for="parent in getOffersParentList" :key="parent" class="menu-offers-list-parent">
              <div
                v-if="
                  getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))) &&
                  getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content
                "
                class="menu-offers-list-parent-title"
              >
                {{ getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.title }}
              </div>
              <div v-for="child in getOffersChildList(parent)" :key="child" class="menu-offers-list-child">
                <NuxtLink
                  v-if="getStoryBySlug(child) && getStoryBySlug(child).content"
                  :key="getStoryBySlug(child).full_slug"
                  :to="`/offers/${getStoryBySlug(child).full_slug?.split('/').pop()}`"
                  :class="$route.path.includes(getStoryBySlug(child).full_slug?.split('/').pop()) ? 'active' : ''"
                  @click="toggleMenu('offers')"
                >
                  {{ getStoryBySlug(child).content.title }}
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
        <div class="backdrop show-sm" @click="toggleMenu('offers')" />
      </div>
      <div v-else-if="currentMenu === 'services'" v-click-outside="() => toggleMenu(null, 0)" class="menu-detail">
        <div class="menu-top-logo-mobile show-sm">
          <NuxtLinkLocale :to="'index'">
            <img
              src="~/assets/logos/logo-stockpro-color.svg"
              :alt="$t('header.logoAlt')"
              :title="$t('header.logoAlt')"
              class="logo"
            />
          </NuxtLinkLocale>
        </div>
        <div class="menu-services">
          <h3 class="menu-services-title">
            <div class="icon-arrow-left u-bg-black show-sm pointer" @click="toggleMenu('mobile')" />
            {{ $t('proArea.services.essentials') }}
          </h3>
          <div class="menu-services-list">
            <div class="menu-services-list-parent">
              <h3 class="menu-services-list-parent-title hide-sm">
                {{ $t('proArea.services.base') }}
              </h3>
              <div v-for="parent in getServicesParentList" :key="parent" class="menu-services-list-parent-items">
                <SbButtonsLinkHover
                  v-if="
                    getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))) &&
                    getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content
                  "
                  :link="{
                    icon: getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.icon
                      ? getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.icon.filename
                      : null,
                    label: getStoryBySlug(parent.substring(0, parent.indexOf('-folder'))).content.title,
                    url: `/services/${getStoryBySlug(parent.substring(0, parent.indexOf('-folder')))
                      .full_slug?.split('/')
                      .pop()}`,
                    labelVisible: true,
                    largeIcon: true,
                  }"
                  @click="toggleMenu('services')"
                />
              </div>
            </div>
            <div class="menu-services-list-children">
              <NuxtLink to="/services/additional" @click="toggleMenu('services')">
                <h3 class="menu-services-list-parent-title">
                  {{ $t('proArea.services.additionnals') }}
                </h3>
              </NuxtLink>
              <div class="menu-services-list-children-grid">
                <div
                  v-for="parent in getServicesParentList"
                  :key="parent"
                  class="menu-services-list-children-grid-items"
                >
                  <div
                    v-for="child in getServicesChildList(parent)"
                    :key="child"
                    class="menu-services-list-children-grid-item"
                  >
                    <NuxtLink
                      v-if="getStoryBySlug(child) && getStoryBySlug(child).content"
                      :to="`/services/additional#${getStoryBySlug(child).full_slug?.split('/').pop()}`"
                      @click="toggleMenu('services')"
                    >
                      <div class="menu-services-list-children-grid-item-img">
                        <img
                          v-if="getStoryBySlug(child).content && getStoryBySlug(child).content.logo"
                          :src="getStoryBySlug(child).content.logo.filename"
                          alt="service logo"
                        />
                      </div>
                      {{ getStoryBySlug(child).content.title }}
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="backdrop show-sm" @click="toggleMenu('services')" />
      </div>
      <div v-else-if="currentMenu === 'myTools'" v-click-outside="() => toggleMenu(null, 0)" class="menu-my-tools">
        <ul>
          <li class="menu-my-tools-item">
            <a :title="$t('header.marketplace')" @click="redirectToMkp()">
              <div class="icon-cart u-bg-clear-blue" />
              <span>{{ $t('header.marketplace') }}</span>
            </a>
          </li>
          <client-only>
            <li v-if="stockButton && tld === 'FR'" class="menu-my-tools-item">
              <a :title="$t('pages.stock')" class="pointer" @click="redirectToMkp('stock')">
                <div class="icon-home u-bg-primary" />
                <span>{{ $t('pages.stock') }}</span>
              </a>
            </li>
          </client-only>
          <li v-if="(user.isPro && user.isOwner) || user.isAdmin" class="menu-my-tools-item">
            <a :title="$t('pages.sellerPage')" @click="redirectToMkp(`merchant/${user.company.slug}`)">
              <div class="icon-pickup u-bg-primary" />
              <span>{{ $t('header.sellerPage') }}</span>
            </a>
          </li>
          <li
            v-if="(user.isPro && user.isOwner && user.hasCompanyOptionsDashboard) || user.isAdmin"
            class="menu-my-tools-item"
          >
            <a :title="$t('pages.dashboard')" @click="redirectToMkp('dashboard')">
              <div class="icon-dashboard u-bg-orange" />
              <span>{{ $t('pages.dashboard') }}</span>
            </a>
          </li>
          <template v-if="$currentUser()?.clubs?.length">
            <li v-for="club in $currentUser?.clubs" :key="club.uid" class="menu-my-tools-item">
              <a v-if="club" :title="$t('pages.club')" @click="redirectToClub(club)">
                <div class="icon-club u-bg-orange" />
                <span>{{ club.name }}</span>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import { useClubStore } from '@/stores/club'

import API from '@/mixins/sp-api-mixin'
import Club from '@/mixins/club-mixin'
import Mobile from '@/mixins/mobile-mixin'
import Scrollto from '@/mixins/scrollto-mixin'
import i18nMx from '@/mixins/i18n-mixin'

import MainButton from '@/components/Buttons/MainButton/MainButton'
import SbButtonsLinkHover from '@/components/Storyblok/SbButtonsLinkHover'
import LangSwitcher from '@/components/I18n/LangSwitcher'

export default {
  name: 'ProHeader',
  components: {
    MainButton,
    SbButtonsLinkHover,
    LangSwitcher,
  },
  mixins: [API, Club, Mobile, Scrollto, i18nMx],
  data() {
    return {
      menuStatus: false,
      companies: [],
      showMenu: false,
      currentMenu: null,
    }
  },
  computed: {
    ...mapGetters('proArea', [
      'getStoryBySlug',
      'getOffersParentList',
      'getOffersChildList',
      'getServicesParentList',
      'getServicesChildList',
    ]),
    isClub() {
      const clubStore = useClubStore()
      return clubStore.isClub
    },
    cartQuantity() {
      const { $cart } = useNuxtApp()
      return $cart().totalItems?.toString()
    },
    user() {
      const { $currentUser } = useNuxtApp()
      return $currentUser()
    },
    myToolLabel() {
      if (!this.isMd) {
        if (this.user.isAuthenticated && this.user.isPro) {
          return this.$t('header.myTools')
        } else {
          return this.$t('header.marketplace')
        }
      }
      return null
    },
    displayFullMenu() {
      if (this.$store?.state?.i18nStore?.tld) {
        // Match fr or FR
        return this.$store?.state?.i18nStore?.tld?.match(/fr/i)
      }
      return true
    },
    stockButton() {
      return !this.isClub && this.user.isPro && !this.user.isFreemium
    },
  },
  methods: {
    logout() {
      const { $cart, $currentUser } = useNuxtApp()
      $currentUser()
        .logout()
        .finally(() => {
          $cart().setIsReady(false)
        })
    },
    handleMenu() {
      this.menuStatus = !this.menuStatus
    },
    toggleMenu(menu, timeout = 100) {
      setTimeout(() => {
        if (this.currentMenu !== menu) {
          this.currentMenu = menu
        } else {
          this.currentMenu = null
        }
      }, timeout)
    },
    myToolsLink() {
      if (this.user.isAuthenticated && this.user.isPro) {
        if (typeof this.toggleMenu === 'function') {
          this.toggleMenu('myTools', 0)
        }
      } else {
        this.redirectToMkp()
      }
    },
    redirectToMkp(slug = '') {
      if (import.meta.client) {
        const host = window.location.host
        const url = `www.${host.substring(host?.indexOf('.') + 1)}`
        window.location.replace(`${window.location.protocol}//${url}/${slug}`)
      }
    },
    redirectToClub(club) {
      if (import.meta.client) {
        const host = window.location.host
        window.location.replace(`${window.location.protocol}//${club.key}.${host.substring(host?.indexOf('.') + 1)}`)
      }
    },
    closeAndRedirect() {
      const localePath = useLocalePath()
      if (typeof this.toggleMenu === 'function') {
        this.toggleMenu(null)
      }
      this.$route.path === localePath('index') ? this.scrollToTop() : null
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/partials/components/submenu';

header.pro-header {
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 9998;
  border-bottom: 2px solid var(--light-grey);

  .inner-container {
    padding-left: 0;
  }

  .menu-top {
    position: relative;
    display: flex;
    align-items: center;
    padding: $header-padding-v 0;
    &-index {
      flex: 1;
    }

    @include mq($mq-sm) {
      padding: $header-padding-v-sm 0;
    }

    &-pages {
      align-items: center;
      display: flex;
      gap: 70px;
      margin-left: 70px;
      flex-grow: 1;
      @include mq($mq-md) {
        gap: $spacing-lg;
        margin-left: $spacing-xl;
      }
      li {
        a {
          cursor: pointer;
          border-radius: $spacing-xs;
          color: var(--dark-blue);
          font-size: pxToRem(15px);
          padding: 5px 10px;
          text-decoration: none;
          &.active {
            font-weight: 800;
          }
          &:hover {
            background-color: var(--transparent-grey);
          }
        }
      }
    }
    &-mobile {
      position: absolute;
      top: 0;
      height: 100vh;
      background-color: var(--white);
      z-index: 9;
      width: 80vw;
      .menu-top-pages {
        align-items: flex-start;
        flex-direction: column;
        margin-top: $spacing-xl;
        margin-left: $spacing-lg;
      }
    }

    .logo {
      margin-left: $spacing-xl;
      max-height: 25px;
      max-width: 145px;
      position: relative;
      top: 4px;
    }
    .searchbar-container {
      flex-grow: 1;
      flex-shrink: 1;

      .searchbar {
        margin-left: 30px;

        @include mq($mq-sm) {
          margin-left: 10px;
        }
      }
    }

    .mkp-pro-btn {
      margin-left: 20px;
      flex-shrink: 0;

      @include mq($mq-sm) {
        display: none;
      }
    }

    .nav-user-wrapper {
      margin-left: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;

      @include mq($mq-lg) {
        margin-left: 20px;
      }

      .user-info {
        text-decoration: none;

        @include mq($mq-sm) {
          display: none;
        }

        .user-name {
          display: block;
          margin: 0 0 2px 0;
          color: var(--night-blue);
          font-weight: 800;
          font-size: 1rem;
          @include line-clamp(1);
          max-width: 200px;

          @include mq($mq-md) {
            max-width: 100px;
          }
        }

        .user-company {
          display: block;
          font-weight: 800;
          font-size: 0.875rem;
          @include line-clamp(1);
          max-width: 200px;

          @include mq($mq-md) {
            max-width: 100px;
          }
        }
      }

      .user-login {
        color: var(--night-blue);
        font-weight: 800;
        font-size: 1rem;
        text-decoration: none;

        @include mq($mq-sm) {
          display: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .nav-user {
        margin-left: 10px;
        display: flex;
        align-items: center;
        & .nav-user-submenu-company-switch-active {
          visibility: visible;
          opacity: 1;
        }
        @include mq($mq-sm) {
          margin-left: 0;
        }
      }
    }
    .submenu-toggle .submenu {
      right: 0px;
    }
    #myToolsButton {
      &:hover {
        background-color: var(--primary);
      }
    }
    .nav-cart {
      margin-left: 20px;
      display: flex;
      align-items: center;

      @include mq($mq-md) {
        margin-left: 15px;
      }
    }
  }
  .menu-detail {
    background-color: var(--white);
    border-bottom: 1px solid var(--light-grey);
    overflow: auto;
    position: absolute;
    top: 81px;
    width: 100%;
    @include mq($mq-sm) {
      position: absolute;
      top: 0;
      height: 100vh;
      background-color: var(--white);
      z-index: 9;
      width: 80vw;
    }
    @include mq($mq-sm) {
      height: 100vh;
      width: 80vw;
      position: fixed;
    }
    a {
      color: var(--dark-blue);
      text-decoration: none;
    }
    .menu-offers {
      @include mq($mq-sm) {
        position: absolute;
        top: 0;
        height: 100vh;
        background-color: var(--white);
        z-index: 9;
        width: 80vw;
      }
      &-title {
        align-items: center;
        border-bottom: 1px solid var(--light-grey);
        display: flex;
        gap: $spacing-sm;
        padding: $spacing-md;
      }
      &-list {
        display: flex;
        padding: $spacing-md;
        @include mq($mq-sm) {
          flex-direction: column;
        }
        &-parent {
          width: 20%;
          max-width: 250px;
          @include mq($mq-md) {
            width: 33%;
          }
          @include mq($mq-sm) {
            width: 100%;
          }
          &-title {
            font-size: pxToRem(15px);
            font-weight: 800;
            margin-bottom: $spacing-lg;
            text-transform: uppercase;
          }
        }
        &-child {
          font-size: pxToRem(15px);
          margin-bottom: $spacing-lg;
          width: 90%;
          a {
            border-radius: $spacing-xxs;
            padding: $spacing-xs $spacing-sm;
            margin: -$spacing-sm;
            &:hover {
              background-color: var(--transparent-grey);
            }
            &.active {
              font-weight: 800;
            }
          }
        }
      }
    }
    .menu-services {
      &-title {
        align-items: center;
        border-bottom: 1px solid var(--light-grey);
        display: flex;
        gap: $spacing-sm;
        padding: $spacing-md;
      }
      &-list {
        display: flex;
        @include mq($mq-sm) {
          flex-direction: column;
        }
        &-parent {
          border-right: 1px solid var(--light-grey);
          padding: $spacing-md;
          width: 30%;
          @include mq($mq-sm) {
            border-right: none;
            width: 100%;
          }
          &-title {
            font-size: pxToRem(15px);
            font-weight: 800;
            margin-bottom: $spacing-md;
            text-transform: uppercase;
          }
          &-items {
            align-items: center;
            display: flex;
            gap: $spacing-sm;
            &:not(:last-child) {
              margin-bottom: $spacing-md;
            }
          }
        }
        &-children {
          padding: $spacing-md;
          width: 100%;
          &-grid {
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            grid-template-columns: 1fr;
            grid-gap: $spacing-md;
            grid-auto-flow: column;
            @include mq($mq-sm) {
              align-items: flex-start;
              display: flex;
              flex-direction: column;
            }
            &-items {
              align-items: center;
              display: grid;
              gap: $spacing-md;
              grid-template-columns: repeat(6, 1fr);
              height: 48px;
              @include mq($mq-sm) {
                display: flex;
                flex-direction: column;
                flex: 1;
                align-items: flex-start;
              }
            }
            &-item {
              display: flex;
              a {
                align-items: center;
                display: flex;
                gap: $spacing-sm;
                text-decoration: none;
              }
              &-img {
                text-align: center;
                img {
                  width: 28px;
                }
              }
              &:hover {
                font-weight: 800;
              }
            }
          }
        }
      }
    }
  }
  .menu-my-tools {
    background: var(--white);
    border-radius: $spacing-sm;
    padding: 10px;
    position: absolute;
    width: 240px;
    top: calc(#{$header-height} - 55px);
    right: 200px;
    @include mq($mq-md) {
      right: 20px;
    }
    &-item {
      font-size: pxToRem(15px);
      padding: $spacing-sm;
      a {
        align-items: center;
        color: var(--dark-blue);
        cursor: pointer;
        display: flex;
        gap: $spacing-sm;
        text-decoration: none;
      }
    }
  }
  .menu-top-logo-mobile {
    padding: $spacing-md;
    border-bottom: 2px solid var(--light-grey);
  }
  .backdrop {
    height: 100vh;
    opacity: 0.5;
    background-color: var(--night-blue);
    z-index: 8;
    position: fixed;
    top: 0;
    right: 0;
    width: 20vw;
  }
}
</style>
